import { v4 as uuidv4 } from 'uuid';
import { mediaElementReadyState } from '../constants/audio';
import { fabric } from 'fabric';
import { createVideoElementForCanvas, updateVideoElement, getVideoStream, replaceWithCDN } from '../helpers/runtimeHelper';
import { deepAssign, getLoaderTextObjectData } from './commonHelper';
import { setLayoutPosition } from './gridLayoutHelper';
import { scaleToBoundSize } from './objectSizingHelper';
import { defaultGlobalBlend } from '../constants/filters';
import { applyBlend } from './blendHelper';
import { getInsertAtIndex } from './commonHelper';

export const getVideoLayerData = () => {
  return {
    props: {
      id: '',
      businessProfileId: '',
      src: '',
      width: 0,
      height: 0,
      duration: 0,
      loop: false,
      startTime: 0,
      endTime: 0
    },
    styles: {
      evented: true,
      selectable: true,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      originX: 'center',
      originY: 'center',
      hasOriginChanged: false,
      muted: false,
      opacity: 1
    },
    filters: {
      globalBlend: JSON.parse(JSON.stringify(defaultGlobalBlend))
    },
    hidden: false,
    isLocked: false,
    adLayoutPositions: [],
    isEditing: false,
    description: '',
    absolutePosition: {
      positionTop: 0,
      positionLeft: 0
    }
  }
}
/*
  If you want to add more properties to the layer, add them above and if they supported for focus mode
  then add them in supportedFocusModeProperties array in externalTools/constants/layers.js
*/

export const addVideoToCanvas = async function (layers, ad, layer, existingLayer) {
  const loaderTextData = getLoaderTextObjectData(layer.data.hidden);
  let loaderTextFabricObject = new fabric.Textbox('Loading video...', loaderTextData);
  ad.canvas && ad.canvas.add(loaderTextFabricObject) && ad.canvas.centerObject(loaderTextFabricObject) && ad.canvas.renderAll();

  const videoElement = await createVideoElementForCanvas(ad, layer.data.props);
  let fabricObject = createVideoFabricObject(ad, videoElement, layer);

  if (existingLayer) {
    fabricObject.layerId = existingLayer.parentId;
    existingLayer.fabricObject = fabricObject;
    existingLayer.isLocked = layer.data.isLocked;
    existingLayer.absoluteProps = existingLayer.absoluteProps ? existingLayer.absoluteProps : {}
  }
  else {
    let newAdLayerId = uuidv4();
    fabricObject.layerId = layer.id;
    const adLayer = {
      id: newAdLayerId,
      parentId: layer.id,
      type: layer.type,
      isLocked: layer.data.isLocked,
      fabricObject: fabricObject,
      absoluteProps: {}
    };
    const layerIndex = layers.findIndex(l => l.id === layer.id);
    ad.layers.splice(layerIndex, 0, adLayer);
    existingLayer = adLayer;
  }
  ad.canvas && ad.canvas.remove(loaderTextFabricObject);
  if(ad.canvas) {
    let canvasLayers = ad.canvas.getObjects();
    if(canvasLayers.length === 1) {
      ad.canvas.insertAt(fabricObject, 0);
    }
    else {
      let insetAtIndex = getInsertAtIndex(layers, canvasLayers, layer.id, layer.id);
      ad.canvas.insertAt(fabricObject, insetAtIndex);
    }
  }
  fabricObject.setCoords();
  ad.canvas.renderAll();

  // Set absolute top and left for the layer
  if(existingLayer && existingLayer.absoluteProps && !existingLayer.absoluteProps.applyAbsolutePosition) {
    existingLayer.absoluteProps.positionTop = fabricObject.top;
    existingLayer.absoluteProps.positionLeft = fabricObject.left;
  }
  return fabricObject;
}

export const updateVideoLayer = (layerToUpdate, data) => {
  deepAssign(layerToUpdate.data, data);
  if(data.adLayoutPositions){
    //Clone array because layerToUpdate.data.adLayoutPositions and data.adLayoutPositions have the same reference
    let clone = data.adLayoutPositions.slice(0);
    // avoiding changing array reference and simply emptying it and pushing the new items
    // This is done so that reactivity is not lost
    layerToUpdate.data.adLayoutPositions.length = 0;
    layerToUpdate.data.adLayoutPositions.push(...clone);
  }
}

export const updateVideoObject = async (payload) => {
  let { ad, layerToUpdate, data, layerData, state, positionDifference } = payload;
  let fabricObject = layerToUpdate.fabricObject;
  const videoElement = fabricObject.getElement();
  let loaderTextFabricObject;
  const oldSrc = videoElement.src;
  if(data.props?.src && oldSrc !== data.props.src){
    if(data.hidden != null){
      const loaderTextData = getLoaderTextObjectData(data.hidden);
      loaderTextFabricObject = new fabric.Textbox('Loading video...', loaderTextData);
      ad.canvas && ad.canvas.add(loaderTextFabricObject) && ad.canvas.centerObject(loaderTextFabricObject) && ad.canvas.renderAll();
    }

    // Supporting variant macros
    if(data.props.renderSrc) {
      // If this update call is coming from node server, then the ffmpeg instance set in the state is passed.
      let ffmpeg = state && state.ffmpeg;
      const meta = await getVideoMetaData(data.props.renderSrc, ffmpeg);
      Object.assign(data.props, { width: meta.width, height: meta.height, duration: meta.duration });
      scaleToBoundSize({ data }, data);
    }

    let videoUrl = data.props.renderSrc || data.props.src;
    await updateVideoElement(fabricObject, videoUrl);
    fabricObject.width = data.props.width;
    fabricObject.height = data.props.height;
  }
  if(data.hidden != null){
    fabricObject.visible = !data.hidden;
  }
  if(data.isLocked != null) {
    fabricObject.selectable = !data.isLocked;
    fabricObject.evented = !data.isLocked;
  }
  if(data.styles){
    deepAssign(fabricObject, data.styles);
  }
  if(data.adLayoutPositions || data.styles?.scaleX || data.styles?.scaleY || (data.props?.src && oldSrc !== data.props.src)){
    setLayoutPosition(ad, fabricObject, layerData.data, null, positionDifference);
  }
  if(data.props?.src && oldSrc !== data.props.src){
    ad.canvas && ad.canvas.remove(loaderTextFabricObject);
  }
  if(data.filters?.globalBlend){
    if(data.filters.globalBlend.enabled != null)
      layerData.data.filters.globalBlend.enabled = data.filters.globalBlend.enabled;
    if(data.filters.globalBlend.operation)
      layerData.data.filters.globalBlend.operation = data.filters.globalBlend.operation;
    applyBlend(fabricObject, layerData.data.filters);
  }
  fabricObject.layerId = layerToUpdate.parentId;

  // Set absolute top and left for the layer
  if (layerToUpdate && layerToUpdate.absoluteProps) {
    if (layerToUpdate.absoluteProps.applyAbsolutePosition) {
      fabricObject.top = layerToUpdate.absoluteProps.positionTop;
      fabricObject.left = layerToUpdate.absoluteProps.positionLeft;
      layerToUpdate.absoluteProps.applyAbsolutePosition = false;
    }
    else {
      layerToUpdate.absoluteProps.positionTop = fabricObject.top;
      layerToUpdate.absoluteProps.positionLeft = fabricObject.left;
    }
  }
  fabricObject.setCoords();
  ad.canvas.renderAll();
}

export const loadMediaElement = async function(mediaElement) {
  let mediaLoadPromise = new Promise((resolve, reject) => {
    mediaElement.load();
    let mediaElementReadyStateTimeout = null;
    // Below function adapted from http://atomicrobotdesign.com/blog/web-development/check-when-an-html5-video-has-loaded/
    function checkLoad() {
      if (mediaElement.readyState === mediaElementReadyState.HAVE_ENOUGH_DATA) {
        clearTimeout(mediaElementReadyStateTimeout)
        resolve();
      }
      else {
        mediaElementReadyStateTimeout = setTimeout(checkLoad, 100);
      }
    }
    checkLoad();
  });
  return mediaLoadPromise;
}

export const createVideoFabricObject = (ad, videoElement, layer) => {
  let fabricObject = new fabric.Image(videoElement, {
    visible: !layer.data.hidden,
    objectCaching: false,
    ...layer.data.styles
  },
  {
    crossOrigin: 'Anonymous'
  });
  setLayoutPosition(ad, fabricObject, layer.data);
  fabricObject.layerId = layer.id;
  return fabricObject;
}

export const createVideoElement = async (videoSrc) => {
  var videoElement = document.createElement('video');
  videoElement.id = 'video_' + uuidv4();
  videoSrc = replaceWithCDN(videoSrc);
  videoElement.src = videoSrc;
  videoElement.controls = true;
  videoElement.style.display = 'none';
  videoElement.crossOrigin = 'anonymous';
  videoElement.preload = 'metadata';
  try {
    await new Promise(async (resolve, reject) => {
      videoElement.addEventListener('loadeddata', () => {
        resolve();
      });
      videoElement.addEventListener('error', () => {
        reject('Error loading video');
      });
      await loadMediaElement(videoElement);
    });
  } catch (error) {
    throw error;
  }
  return videoElement;
}

export const getVideoMetaData = async (videoSrc, ffmpeg) => {
  if (ffmpeg) {
    // if ffmpeg instance is passed, then use it to get the video metadata
    const videoStream = await getVideoStream(videoSrc, ffmpeg);
    return {
      width: videoStream.width,
      height: videoStream.height,
      duration: videoStream.duration
    }
  }
  else {
    const videoElement = await createVideoElement(videoSrc);
    return {
      width: videoElement.videoWidth,
      height: videoElement.videoHeight,
      duration: videoElement.duration
    }
  }
}

export const hasVideoChanged = (layerOne, layerTwo) => {
  return layerOne.data.props.id !== layerTwo.data.props.id || layerOne.data.props.src !== layerTwo.data.props.src;
}
