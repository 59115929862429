const TWEEN = require('@tweenjs/tween.js');
import { animateConstants, typeNames, TWEEN_START_VALUE, TWEEN_END_VALUE, MIN_TWEEN_DURATION, currentValueConstants } from '../constants/animation';
import { standardCanvasWidth } from '../constants/commonData';
import { getRelativeLength } from './tweenUtils';

/**
 * A transition item's definition.
 * @typedef {Object} TransitionItem
 * @property {string} name - the name of the transistion. Defined in {typeNames}.
 * @property {function} getTweens - a function that generates the Tweens for this transistion type.
 */
/**
 * The supported transitions for start and end animation. Used in animationMutations.startAnimation and AnimationPreview.vue
 * @type {TransitionItem[]}
 */
export const startAndEndTransitions = [
  {
    name: typeNames.fade,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const fadeStart = configs.fadeStart == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () { Object.assign(target, { opacity: fadeStart }); })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.slideHorizontal,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentValueConstants.currentPosition ? updatedTarget.left : (Math.ceil(configs.slideStart) / 100) * canvas.width;
      const slideEnd = configs.slideEnd == currentValueConstants.currentPosition ? updatedTarget.left : (Math.ceil(configs.slideEnd) / 100) * canvas.width;
      const newTweens = [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              left: slideStart,
              opacity: updatedTarget.opacity
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ left: slideEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
      return newTweens;
    }
  },
  {
    name: typeNames.slideVertical,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentValueConstants.currentPosition ? updatedTarget.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
      const slideEnd = configs.slideEnd == currentValueConstants.currentPosition ? updatedTarget.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              top: slideStart,
              opacity: updatedTarget.opacity
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ top: slideEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.fadeSlideHorizontal,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentValueConstants.currentPosition ? updatedTarget.left : (Math.ceil(configs.slideStart) / 100) * canvas.width;
      const slideEnd = configs.slideEnd == currentValueConstants.currentPosition ? updatedTarget.left : (Math.ceil(configs.slideEnd) / 100) * canvas.width;
      const fadeStart = configs.fadeStart == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              left: slideStart,
              opacity: fadeStart
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ left: slideEnd, opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.fadeSlideVertical,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const slideStart = configs.slideStart == currentValueConstants.currentPosition ? updatedTarget.top : (Math.ceil(configs.slideStart) / 100) * canvas.height;
      const slideEnd = configs.slideEnd == currentValueConstants.currentPosition ? updatedTarget.top : (Math.ceil(configs.slideEnd) / 100) * canvas.height;
      const fadeStart = configs.fadeStart == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
      const fadeEnd = configs.fadeEnd == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target, {
              top: slideStart,
              opacity: fadeStart
            });
          })
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ top: slideEnd, opacity: fadeEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  },
  {
    name: typeNames.rotate,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const angleStart = configs.angleStart == currentValueConstants.currentAngle ? updatedTarget.angle : Math.ceil(configs.angleStart);
      const angleEnd = configs.angleEnd == currentValueConstants.currentAngle ? updatedTarget.angle : Math.ceil(configs.angleEnd);
      const rotations = configs.rotations;
      let rotationDuration = duration / rotations;
      configs.retrace && (rotationDuration /= 2);
      const tweens = [];
      for(let i = 0; i < rotations; i++) {
        tweens.push(
          new TWEEN.Tween(o, timeline)
            .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
            .onStart(function() {
              Object.assign(target,
                {
                  angle: angleStart,
                  opacity: updatedTarget.opacity
                });
            })
            .delay(startDelay)
        )
        tweens.push(
          new TWEEN.Tween(target, timeline)
            .to({ angle: angleEnd }, rotationDuration)
            .easing(TWEEN.Easing[easing.ease][easing.type])
        )
        if(configs?.retrace) {
          tweens.push(
            new TWEEN.Tween(o, timeline)
              .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
              .onStart(function() {
                Object.assign(target, { angle: angleEnd });
              })
          )
          tweens.push(
            new TWEEN.Tween(target, timeline)
              .to({ angle: angleStart }, rotationDuration)
              .easing(TWEEN.Easing[easing.ease][easing.type])
          )
        }
      }
      return tweens;
    }
  },
  {
    name: typeNames.scale,
    getTweens: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
      const o = { value: TWEEN_START_VALUE };
      const scaleStart = configs.scaleStart == currentValueConstants.currentScale ? configs.scaleStart : Math.ceil(configs.scaleStart) / 100;
      const scaleEnd = configs.scaleEnd == currentValueConstants.currentScale ? configs.scaleEnd : Math.ceil(configs.scaleEnd) / 100;
      const scaleXStart = scaleStart == currentValueConstants.currentScale ? updatedTarget.scaleX : scaleStart;
      const scaleYStart = scaleStart == currentValueConstants.currentScale ? updatedTarget.scaleY : scaleStart;
      const scaleXEnd = scaleEnd == currentValueConstants.currentScale ? updatedTarget.scaleX : scaleEnd;
      const scaleYEnd = scaleEnd == currentValueConstants.currentScale ? updatedTarget.scaleY : scaleEnd;
      return [
        new TWEEN.Tween(o, timeline)
          .to({ value: TWEEN_END_VALUE }, MIN_TWEEN_DURATION)
          .onStart(function () {
            Object.assign(target,
              {
                scaleX: scaleXStart,
                scaleY: scaleYStart,
                opacity: updatedTarget.opacity
              })})
          .delay(startDelay),
        new TWEEN.Tween(target, timeline)
          .to({ scaleX: scaleXEnd, scaleY: scaleYEnd }, duration)
          .easing(TWEEN.Easing[easing.ease][easing.type])
      ];
    }
  }
];

export const middleTransitions = {
  Blink: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const blinkCount = Math.ceil(configs.count);
    const opacityValues = Array(blinkCount)
      .fill([0.2, updatedTarget.opacity])
      .flat();
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        opacity: opacityValues
      }, duration)
        .delay(startDelay)
    ];
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Move: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const moveDuration = configs.intensity;
    const moveDistance = configs.distance;
    const leftValues = [];
    const remainder = duration % moveDuration;
    const count = Math.ceil(duration / moveDuration);
    const length = getRelativeLength(moveDistance, standardCanvasWidth, canvas.width);
    const moveLeft = (updatedTarget.left - length).toFixed(10) * 1;
    const moveRight = (updatedTarget.left + length).toFixed(10) * 1;
    let left = moveLeft;
    let lastTween;
    for (let i = 0; i < count; i++) {
      left = (left == moveLeft) ? moveRight : moveLeft;
      if (i == (count - 1)) {
        left = updatedTarget.left;
        if (remainder != 0) {
          lastTween = new TWEEN.Tween(target, timeline).to(
            {
              left: [left]
            }, remainder);
          break;
        }
      }
      leftValues.push(left);
    }
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        left: leftValues
      }, duration - remainder)
        .delay(startDelay)
    ];
    if (lastTween)
      tweens.push(lastTween);
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Spin: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const tweens = [
      new TWEEN.Tween(target, timeline).to({}, 0)
        .delay(startDelay)
    ];
    const spinCount = Math.ceil(configs.count);
    const angle = updatedTarget.angle;
    const spinValues = [];
    for (let i = 0; i < spinCount; i++) {
      spinValues.push(angle + (i + 1) * animateConstants.spinDeg);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({
      angle: spinValues
    }, duration));
    tweens.push(new TWEEN.Tween(target, timeline).to({ angle }, 0));
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  HeartBeat: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const backUp = { scaleX: updatedTarget.scaleX, scaleY: updatedTarget.scaleY };
    const tweens = [
      new TWEEN.Tween(target, timeline).to({}, 0)
        .delay(startDelay)
    ];
    const heartBeatCount = Math.ceil(configs.count);
    const heartBeatDuration = duration / heartBeatCount;
    const scaleX = updatedTarget.scaleX / 100 * 85;
    const scaleY = updatedTarget.scaleY / 100 * 85;
    for (let i = 0; i < heartBeatCount; i++) {
      tweens.push(new TWEEN.Tween(target, timeline).to({
        scaleX: [scaleX, updatedTarget.scaleX, scaleX, updatedTarget.scaleX],
        scaleY: [scaleY, backUp.scaleY, scaleY, backUp.scaleY]
      }, heartBeatDuration / 2)
        .delay(i == 0 ? i : heartBeatDuration / 2));
    }
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  },
  Pulsate: (target, timeline, updatedTarget, duration, startDelay, easing, canvas, configs) => {
    const fadeStart = configs.fadeStart == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeStart) / 100) ;
    const fadeEnd = configs.fadeEnd == currentValueConstants.currentOpacity ? updatedTarget.opacity : (Math.ceil(configs.fadeEnd) / 100);
    const scaleStart = configs.scaleStart == currentValueConstants.currentScale ? configs.scaleStart : Math.ceil(configs.scaleStart) / 100;
    const scaleEnd = configs.scaleEnd == currentValueConstants.currentScale ? configs.scaleEnd : Math.ceil(configs.scaleEnd) / 100;
    const scaleXStart = scaleStart == currentValueConstants.currentScale ? updatedTarget.scaleX : scaleStart;
    const scaleYStart = scaleStart == currentValueConstants.currentScale ? updatedTarget.scaleY : scaleStart;
    const scaleXEnd = scaleEnd == currentValueConstants.currentScale ? updatedTarget.scaleX : scaleEnd;
    const scaleYEnd = scaleEnd == currentValueConstants.currentScale ? updatedTarget.scaleY : scaleEnd;
    const pulsateCount = configs.count ?? animateConstants.defaultMiddleAnimationCount;
    const tweens = [
      new TWEEN.Tween(target, timeline).to({
        opacity: fadeStart,
        scaleX: scaleXStart,
        scaleY: scaleYStart
      }, 0)
        .delay(startDelay)
    ];
    const scaleXValues = [];
    const scaleYValues = [];
    const opacityValues = [];
    for (let i = 0; i < pulsateCount; i++) {
      opacityValues.push(fadeEnd);
      scaleXValues.push(scaleXEnd);
      scaleYValues.push(scaleYEnd);
      opacityValues.push(fadeStart);
      scaleXValues.push(scaleXStart);
      scaleYValues.push(scaleYStart);
    }
    tweens.push(new TWEEN.Tween(target, timeline).to({ opacity: opacityValues, scaleX: scaleXValues, scaleY: scaleYValues }, duration));
    tweens.forEach(x => x.easing(TWEEN.Easing[easing.ease][easing.type]));
    return tweens;
  }
};
