import { levels } from '../constants/textStyles';

export const minTime = 0;
export const maxTime = 120;
export const minimumDuration = 0.1;
export const direction = { forward: 'forward', backward: 'backward' };
export const timings = { startTime: 'startTime', endTime: 'endTime' };
export const minimumPositionWidth = 1;
export const maxRecordingWait = 0.0;
export const lineSegmentsCount = 10; // 10 hatch marks for each second on ruler.
export const maxDeltaY = 75;
export const adSwitchWait = 500; // In milli sec
export const MIN_TWEEN_DURATION = 0;
export const TWEEN_START_VALUE = 0;
export const TWEEN_END_VALUE = 1;
export const fabricMediaElementIndex = 0; // Media element at this index is used by fabric object.
export const duplicateMediaElementIndex = 1; // Media element at this index is used to get audio during download.
export const lastResetWait = 20;
export const decreaseLetterLevelDuration = 30;
export const defaultFps = 30;
export const defaultAudioBitrate = 128; // kb / s

export const loopTypes = {
  once: 1,
  forever: 100
}
export const foreverLoop = Infinity;

export const download = {
  minOpacityChange: 0.001
}

const intensityConstants = {
  move: {
    low: 200,
    medium: 100,
    hard: 70,
    intense: 30
  }
}

export const middleAnimationIntensities = {
  move: [
    {
      name: 'message.low',
      value: intensityConstants.move.low
    },
    {
      name: 'message.medium',
      value: intensityConstants.move.medium
    },
    {
      name: 'message.hard',
      value: intensityConstants.move.hard
    },
    {
      name: 'message.intense',
      value: intensityConstants.move.intense
    }
  ]
}

export const animateConstants = {
  rotateByDegrees: '90',
  scaleStart: '0',
  scaleEnd: '1',
  rotateStart: '0',
  rotateEnd: '360',
  rotations: 1,
  spinDeg: '360',
  slideLength: '20',
  minScale: '0',
  maxScale: '1.2',
  minOpacity: '0',
  maxOpacity: '1',
  moveLength: '10',
  defaultMiddleAnimationCount: 2,
  defaultMiddleTextAnimationCount: 1,
  middleAnimateDuration: 600,
  moveDuration: 100,
  fontSizeScale: 0.85,
  defaultEase: 'easeLinear',
  slideStart: '0',
  slideEnd: '100'
}

export const typeNames = {
  fade: 'Fade',
  blink: 'Blink',
  move: 'Move',
  heartBeat: 'HeartBeat',
  pulsate: 'Pulsate',
  spin: 'Spin',
  slideHorizontal: 'Slide Horizontal',
  slideVertical: 'Slide Vertical',
  fadeSlideHorizontal: 'Fade Slide Horizontal',
  fadeSlideVertical: 'Fade Slide Vertical',
  rotate: 'Rotate',
  scale: 'Scale',
  typeWriter: 'TypeWriter',
  none: 'None'
}

export const positions = {
  start: 'start',
  middle: 'middle',
  end: 'end'
}

export const animationLayerTypes = {
  static: 'static',
  dynamic: 'dynamic'
}

export const starImage = '/img/icons/star.png';

const nonLayerAnimationTypes = {
  start: [
    { name: typeNames.fade },
    { name: typeNames.slideVertical },
    { name: typeNames.fadeSlideVertical },
    { name: typeNames.scale },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ],
  middle: [
    { name: typeNames.blink },
    { name: typeNames.pulsate },
    { name: typeNames.heartBeat },
    { name: typeNames.none }
  ],
  end: [
    { name: typeNames.fade },
    { name: typeNames.slideVertical },
    { name: typeNames.fadeSlideVertical },
    { name: typeNames.scale },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ]
}

export const animationTypes = {
  [levels.layer]: {
    start: [
      { name: typeNames.fade },
      { name: typeNames.slideVertical },
      { name: typeNames.slideHorizontal },
      { name: typeNames.fadeSlideVertical },
      { name: typeNames.fadeSlideHorizontal },
      { name: typeNames.rotate },
      { name: typeNames.scale },
      { name: typeNames.none }
    ],
    middle: [
      { name: typeNames.blink },
      { name: typeNames.move },
      { name: typeNames.pulsate },
      { name: typeNames.heartBeat },
      { name: typeNames.spin },
      { name: typeNames.none }
    ],
    end: [
      { name: typeNames.fade },
      { name: typeNames.slideVertical },
      { name: typeNames.slideHorizontal },
      { name: typeNames.fadeSlideVertical },
      { name: typeNames.fadeSlideHorizontal },
      { name: typeNames.rotate },
      { name: typeNames.scale },
      { name: typeNames.none }
    ]
  },
  [levels.letter]: nonLayerAnimationTypes,
  [levels.line]: nonLayerAnimationTypes,
  [levels.word]: nonLayerAnimationTypes
}

export const easingFunctions = [
  {
    fabricName: animateConstants.defaultEase,
    ease: 'Linear',
    type: 'None'
  },
  {
    fabricName: 'easeInQuad',
    ease: 'Quadratic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuad',
    ease: 'Quadratic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuad',
    ease: 'Quadratic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuart',
    ease: 'Quartic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuart',
    ease: 'Quartic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuart',
    ease: 'Quartic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuint',
    ease: 'Quintic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuint',
    ease: 'Quintic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuint',
    ease: 'Quintic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInSine',
    ease: 'Sinusoidal',
    type: 'In'
  },
  {
    fabricName: 'easeOutSine',
    ease: 'Sinusoidal',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutSine',
    ease: 'Sinusoidal',
    type: 'InOut'
  },
  {
    fabricName: 'easeInExpo',
    ease: 'Exponential',
    type: 'In'
  },
  {
    fabricName: 'easeOutExpo',
    ease: 'Exponential',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutExpo',
    ease: 'Exponential',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCirc',
    ease: 'Circular',
    type: 'In'
  },
  {
    fabricName: 'easeOutCirc',
    ease: 'Circular',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCirc',
    ease: 'Circular',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInElastic',
    ease: 'Elastic',
    type: 'In'
  },
  {
    fabricName: 'easeOutElastic',
    ease: 'Elastic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutElastic',
    ease: 'Elastic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBack',
    ease: 'Back',
    type: 'In'
  },
  {
    fabricName: 'easeOutBack',
    ease: 'Back',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBack',
    ease: 'Back',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBounce',
    ease: 'Bounce',
    type: 'In'
  },
  {
    fabricName: 'easeOutBounce',
    ease: 'Bounce',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBounce',
    ease: 'Bounce',
    type: 'InOut'
  }
]

export const resetProperties = ['top', 'left', 'opacity', 'scaleY', 'scaleX', 'originX', 'originY', 'angle', 'visible', 'styles', 'padding', 'text', 'currentTime'];

export const animationPlayModes = {
  playAll: 'playAll',
  playSelected: 'playSelected',
  playEach: 'playEach'
}

export const stylesProperties = {
  fill: 'fill',
  deltaY: 'deltaY',
  fontSize: 'fontSize',
  top: 'top',
  left: 'left'
}

export const animationUpdateTypes = {
  LAYER: 'layer',
  ANIMATION_POSITION: 'animationPosition',
  ANIMATION_SOURCE_POSITION: 'animationSourcePosition'
}

export const animationConfigsRange = {
  fade: {
    min: '0',
    max: '100'
  },
  rotations: {
    min: '0',
    max: '50'
  },
  scale: {
    min: '0',
    max: '2000'
  },
  angle: {
    min: '-360',
    max: '360'
  },
  distance: {
    min: '0',
    max: '200'
  }
}

export const currentValueConstants = {
  currentScale: 'Current_Scale',
  currentOpacity: 'Current_Opacity',
  currentPosition: 'Current_Position',
  currentAngle: 'Current_Angle'
}

export const animationConfigInitialValues = {
  fade: {
    fadeStart: '0',
    fadeEnd: currentValueConstants.currentOpacity
  },
  scale: {
    scaleStart: '0',
    scaleEnd: currentValueConstants.currentScale
  },
  slide: {
    slideStart: '0',
    slideEnd: currentValueConstants.currentPosition
  },
  fadeSlide: {
    slideStart: '0',
    slideEnd: currentValueConstants.currentPosition,
    fadeStart: '0',
    fadeEnd: currentValueConstants.currentOpacity
  },
  rotate: {
    angleStart: '0',
    angleEnd: '360',
    rotations: 1,
    retrace: false
  },
  heartbeat: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  spin: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  blink: {
    count: animateConstants.defaultMiddleAnimationCount
  },
  move: {
    intensity: intensityConstants.move.medium,
    distance: '10'
  },
  pulsate: {
    fadeStart: currentValueConstants.currentOpacity,
    fadeEnd: '0',
    scaleStart: currentValueConstants.currentScale,
    scaleEnd: '0',
    count: animateConstants.defaultMiddleAnimationCount
  }
}

export const textAnimationConfigInitialValues = {
  fade: {
    fadeStart: animateConstants.minOpacity,
    fadeEnd: currentValueConstants.currentOpacity
  },
  scale: {
    scaleStart: animateConstants.scaleStart,
    scaleEnd: currentValueConstants.currentScale
  },
  slide: {
    slideStart: animateConstants.slideStart,
    slideEnd: currentValueConstants.currentPosition
  },
  fadeSlide: {
    slideStart: animateConstants.slideStart,
    slideEnd: currentValueConstants.currentPosition,
    fadeStart: animateConstants.minOpacity,
    fadeEnd: currentValueConstants.currentOpacity
  },
  heartbeat: {
    count: animateConstants.defaultMiddleTextAnimationCount
  },
  blink: {
    count: animateConstants.defaultMiddleTextAnimationCount
  },
  pulsate: {
    fadeStart: animateConstants.minOpacity,
    fadeEnd: currentValueConstants.currentOpacity,
    scaleStart: currentValueConstants.currentScale,
    scaleEnd: animateConstants.scaleStart,
    count: animateConstants.defaultMiddleTextAnimationCount
  }
}

const startAndEndAnimationPreviewContants = {
  'Fade': {
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Scale': {
    scaleStart: animateConstants.scaleStart.toString(),
    scaleEnd: (animateConstants.scaleEnd * 100).toString()
  },
  'Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString()
  },
  'Slide Horizontal': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString()
  },
  'Fade Slide Horizontal': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString(),
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Fade Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: animateConstants.slideEnd.toString(),
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Rotate': {
    angleStart: animateConstants.rotateStart.toString(),
    angleEnd: animateConstants.rotateEnd.toString(),
    rotations: animateConstants.rotations,
    retrace: false
  }
}

export const animationPreviewConstants = {
  start: startAndEndAnimationPreviewContants,
  middle: {
    'Blink': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'Move': {
      intensity: animateConstants.moveDuration,
      distance: animateConstants.moveLength.toString()
    },
    'Spin': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'HeartBeat': {
      count: animateConstants.defaultMiddleAnimationCount
    },
    'Pulsate': {
      fadeStart: animateConstants.minOpacity.toString(),
      fadeEnd: (animateConstants.maxOpacity * 100).toString(),
      scaleStart: animateConstants.scaleStart.toString(),
      scaleEnd: (animateConstants.scaleEnd * 100).toString(),
      count: animateConstants.defaultMiddleAnimationCount
    }
  },
  end: startAndEndAnimationPreviewContants
}

const startAndEndTextAnimationPreviewContants = {
  'Fade': {
    fadeStart: animateConstants.minOpacity.toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  },
  'Scale': {
    scaleStart: animateConstants.scaleStart.toString(),
    scaleEnd: (animateConstants.scaleEnd * 100).toString()
  },
  'Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: currentValueConstants.currentPosition
  },
  'Fade Slide Vertical': {
    slideStart: animateConstants.slideStart.toString(),
    slideEnd: currentValueConstants.currentPosition,
    fadeStart: (animateConstants.minOpacity * 100).toString(),
    fadeEnd: (animateConstants.maxOpacity * 100).toString()
  }
}

export const textAnimationPreviewConstants = {
  start: startAndEndTextAnimationPreviewContants,
  middle: {
    'Blink': {
      count: animateConstants.defaultMiddleTextAnimationCount
    },
    'HeartBeat': {
      count: animateConstants.defaultMiddleTextAnimationCount
    },
    'Pulsate': {
      fadeStart: animateConstants.minOpacity.toString(),
      fadeEnd: (animateConstants.maxOpacity * 100).toString(),
      scaleStart: currentValueConstants.currentScale,
      scaleEnd: animateConstants.scaleStart.toString(),
      count: animateConstants.defaultMiddleTextAnimationCount
    }
  },
  end: startAndEndTextAnimationPreviewContants
}

export const nonSupportedAnimationTypesForTextLevel = [typeNames.move, typeNames.spin, typeNames.slideHorizontal, typeNames.fadeSlideHorizontal, typeNames.rotate]

export const existingTemplatesConstants = {
  pingFadeStart: '30',
  fadeStart: '0',
  zoomInScaleEnd: '120',
  scaleDownScaleStart: '120',
  pulsateScaleEnd: '120',
  layerPulsateScaleEnd: '125',
  pingScaleEnd: '0',
  scaleUpScaleEnd: '120',
  ScaleDownScaleEnd: '0',
  fadeEnd: '0'
}